/* Scss Document */


.animate {
	
	.animate-bounce {
		
	  opacity: 0;
	  -moz-transition: all 700ms ease-out;
	  -webkit-transition: all 700ms ease-out;
	  -o-transition: all 700ms ease-out;
	  transition: all 700ms ease-out;
	  -moz-transform: translate3d(0px, 200px, 0px);
	  -webkit-transform: translate3d(0px, 200px, 0px);
	  -o-transform: translate(0px, 200px);
	  -ms-transform: translate(0px, 200px);
	  transform: translate3d(0px, 200, 0px);
	  -webkit-backface-visibility: hidden;
	  -moz-backface-visibility: hidden;
	  backface-visibility: hidden;
	  
	}
	
	.animate-fade-in {
		
	
		opacity: 0;
	  -moz-transition: all 700ms ease-out;
	  -webkit-transition: all 700ms ease-out;
	  -o-transition: all 700ms ease-out;
	  transition: all 700ms ease-out;
	  -webkit-backface-visibility: hidden;
	  -moz-backface-visibility: hidden;
	  backface-visibility: hidden;
	
		
	}

}
.animate.in-view { 

	.animate-bounce {
		
	  opacity: 1;
	  -moz-transform: translate3d(0px, 0px, 0px);
	  -webkit-transform: translate3d(0px, 0px, 0px);
	  -o-transform: translate(0px, 0px);
	  -ms-transform: translate(0px, 0px);
	  transform: translate3d(0px, 0px, 0px);
  
	}
	
	
	.animate-fade-in {
		
		opacity: 01;
		
	}
	
	
}




