/* Scss Document */

.trigger {

	cursor:pointer;	
	
}
.button {
	
	font: {
		
		family:$font-family-button;
		weight:$font-weight-button;
		size:1.25em;
		
	}
	
	padding:.5em 1.25em .625em 1.25em;
	
	display:inline-block;
	background-color:#fff;
	text-align:center;
	
	color:$font-color-button;
	border: solid thin $border-color-button;
	
	&:hover {
		
		color:$font-color-button-hover;
		border: solid thin $border-color-button-hover;
		
	}
	
}



.button-small {
	
	@include rounded(.25em);
	
	
	font: {
		
	family:$font-family-button;
	weight:$font-weight-button;
	size:.875em 	;
		
	}
	
	
	padding:.5em 1.25em .5em 1.25em;
	
	display:inline-block;
	background-color:#fff;
	text-align:center;
	
	color:$font-color-button;
	border: solid thin $border-color-button;
	
	&:hover {
		
		color:$font-color-button-hover;
		border: solid thin $border-color-button-hover;
		
	}
	
}

.button-google {
	
	background:url(../svg/icon-google-plus-white.svg);
	
}

.button-facebook {
	
	background:url(../svg/icon-facebook-white.svg);
	
}

.button-houzz {
	
	background:url(../svg/icon-houzz-white.svg);
	
}


.button-google, .button-facebook, .button-houzz {
	
	display:block;
	color:#fff;
	font: {
		
		size:1.25em;
		family:$font-family-button;
		weight:$font-weight-button;
	}
	
	line-height:50px;
			
	padding: 0 0 0 50px;
	
	background: {
	
		repeat:no-repeat;
		size:44px 44px;
		position: left center;	
		
	}
	
}

.button-search-text-white {
	
	display:block;
	
	cursor:pointer;
	
	color:#fff;	
	
	font: {
		
		family:$font-family-headline-bold;
		weight:$font-weight-headline-bold;
		size:1.25em;
		
	}
	
	background: {
		
		image:url('/ui/svg/icon-search-white.svg');
		repeat:no-repeat;
		position:left center
		
	}
	
	padding:.25em 1.5em .25em 40px;
	
	// margin: 0 0 0 1.5em;
	
	line-height:1.75em;
	
}



.button-menu-text-black {
	
	display:block;
	
	cursor:pointer;
	
	color:#A3A3A3;	
	
	font: {
		
		family:$font-family-headline-bold;
		weight:$font-weight-headline-bold;
		size:1em;
		
	}
	
	@media (min-width:$break-tablet) {
		
		font-size:1.25em;
		
	}
	
	
	
	
	background: {
		
		image:url('/ui/svg/icon-menu-small-grey.svg');
		repeat:no-repeat;
		position:left center;
		
	}
	
	padding:.25em 1.5em .25em 40px;
	
	margin: 0 0 0 1em;
	
	line-height:1.75em;
	
	&:hover {
	
		color:#000;	
		
	}
	
}

.button-close-white {

	
	display:none;
	
	
	
	cursor:pointer;
	
	width:44px;
	height:44px;
	
	position:absolute;
	
	top:6px;
	right:6px;
	
	background: {
		
		image:url('/ui/svg/icon-close.svg');
		repeat:no-repeat;
		position:center;
		
	}
	
	
}

.button-close-white.open {
	
	display:block;	
	
}

.button-close-black {

	
	display:none;
	
	
	
	cursor:pointer;
	
	width:44px;
	height:44px;
	
	position:absolute;
	
	top:6px;
	right:6px;
	
	background: {
		
		image:url('/ui/svg/icon-close-black.svg');
		repeat:no-repeat;
		position:center;
		
	}
	
	
}

.button-close-black.open {
	
	display:block;	
	
}