/* Scss Document */

// use for structural navigation

a {

	color: $font-color-body-link;
	
	&:hover {
		
		color: $font-color-body-link-hover;	
		
		transition:ease-in-out .5s;
		
	}
	
}






.nav-main {
	

	display:none;
	
}


.nav-main-open {
	
	display:block;
	position:relative;
	top:85px;
	
	@media (min-width:$break-tablet) {
		
		top:110px;
		
	}
	
	
	background-color:#fff;
	
	
	z-index:20 !important;
	
		
		nav {
			
			@media (min-width:$break-tablet) and (max-width:$break-desktop) {
				
			
			width:55%;
			float:left;	
				
				
			}
			
			
			@media (min-width:$break-desktop)  {
				
			
			width:60%;
			float:left;	
				
				
			}			
			
			
			a {
				
				display:block;
				
			}
			
		}
		
		
			
		.contact {
		
			@media (min-width:$break-tablet) and (max-width:$break-desktop) {
				
			
			width:40%;
			float:right;	
				
				
			}
			
			@media (min-width:$break-desktop) {
				
			
			width:35%;
			float:right;	
				
				
			}
			
						
			.branding  {
					
					
			}
			
		}
			
		
}









footer {
	
		.title {
			
			display:block;
			margin:1.5em 1em 0 1em;
			
			font-size:2em;
			
			line-height:1.5em;
			
				@media (min-width:$break-tablet) and (max-width:$break-desktop) {
					
					font-size:1.5em;
					margin:.5em 1em 0 1em;
					
				}
				
				
				@media (min-width:$break-desktop) {
					
					font-size:1em;
					margin:.5em 1em 0 1em;
				}
			
			
		}
	
		nav {
			
				margin:0 1em 0 1em;
				
				
				
				
				
				
			
			a {
				
				display:block;
				
				margin:.25em 0 .25em 0;
				
				color:$font-color-footer-link;
				
				line-height:1.25em;
				
				font-size:2em;
				
				@media (min-width:$break-tablet) and (max-width:$break-desktop) {
					
					padding:.25em 0 .25em 0;
					font-size:1em;
					
				}
				
				
				@media (min-width:$break-desktop) {
					
					padding:.25em 0 .25em 0;
					font-size:1em;
					
				}
				
				
				&:hover {
					
					color:$font-color-footer-link-hover;
					
				}
				
			}
			
		}
		
}


// PAGINATION

.paging {
	

	text-align:center;
	margin:4em auto 10em auto;
  
	
}

.paging-small {
	

	text-align:center;
	margin:1em auto 2em auto;
  
	
}


.pagination {
	
		
  display: block;
  padding: 0;
  text-align:center;

 
  
  a, span {
	
		display:inline-block;
		
		text-align:center;
		
		font-family:$font-family-headline-bold;
		
		
		line-height:3em;  
		
		padding:0 1.5em 0 1.5em;
		
		margin-right:-4px;
		
		border-top:solid thin $border-color-button;
		
		border-bottom:solid thin $border-color-button;
		
		&:hover {
		
		background-color:#E8E8E8;	
		
		}
	  
  }
  
  a:first-child {
			
			 border-left: solid thin $border-color-button;
			 border-top-left-radius: 4px;
 			 border-bottom-left-radius: 4px;
			
		}

	a:last-child {
			
			 border-right: solid thin $border-color-button;
			 border-top-right-radius: 4px;
 			 border-bottom-right-radius: 4px;
			
	}
	
	
	
	a.active, span.active {
	
		color:#fff;
		background-color:$border-color-button	;
		
			&:first-child {
				
				 border-left: solid thin $border-color-button;
				 border-top-left-radius: 4px;
				 border-bottom-left-radius: 4px;
				
			}
	
			&:last-child {
					
					 border-right: solid thin $border-color-button;
					 border-top-right-radius: 4px;
					 border-bottom-right-radius: 4px;
					
			}
				
	}
  
	
}

#breadcrumbs {
	
	display:none;
	

	
	
	@media (min-width:$break-tablet) {
		
	display:block;
	
	position:absolute;
	
	top:160px;
	left:0;
	right:0;
	
	text-align:left;
	
	z-index:2;			
		
	}
	
	
	.content {
	
		@include width-page;


		a {
			
				display:inline-block;
				
				line-height:2em;
				
				margin:0 1em;
				
				padding:0 1em 0 1em;
				
				color:#fff;
		
				&:last-child {
						
						 font-weight:bold;
						 
						 font-weight:900;
						
				}
				
				&:hover {
					
					background-color:#000;	
					
					@include rounded(.25em);
					
				}
		
		}
	
	}
	
}

#page {
	
	#breadcrumbs {
		
		display:none;
	

	
	
		@media (min-width:$break-tablet) {
			
		display:block;
		
		position:relative;
		
		top:auto;
		left:auto;
		right:auto;
		
		margin:1em auto 1em auto;
		
		text-align:left;
		
		z-index:auto;			
			
		}
		
		a {
			
			color:$font-color-body-link;
			
			&:hover {
			
				color:$font-color-body-link-hover;	
				background-color:$color-background-branding-2;
				
			}
			
		}
		
		
	}
	
}

.nav-tags {
	
	a {
	
		display:inline-block;
		
		@include rounded(.25em);
		
		text-align:center;
		
		color:#fff;
		
		font: {
			
			family:$font-family-accent;
			size:1em;
			
		}
		
		padding:.25em 1em .25em 1em;
		margin:.25em .75em .25em 0;
		
		background-color: $color-background-branding-1;
		
		&:hover {
			
			background-color:$color-background-branding-3;
			
		}
		
	}
	
}

#banners-side {
	
		nav {
			
			text-align:left;
			
			a.current {
				
				color:#000;
				
			}
			
			
			a {
				
				display:block;
				
				font: {
					
						size:1.5em;
						family: $font-family-headline-bold;
						weight: $font-weight-headline-bold;

				}
				
				line-height:1.25em;
				padding:.75em 1em .75em 1.5em;
				
			}
			
		}
		
}