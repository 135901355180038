/* Scss Document */


/**
 * Note: I have omitted any vendor-prefixes for clarity.
 * Adding them is left as an exercise for the reader.
 */
.headroom {
    transition: transform 200ms linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}


.headroom--not-top {
		
		.logo { 
		
			opacity:0 !important; 
			
			}
			
			
}