/* Variables */

// BREAK POINTS

$break-desktop: 960px;

$break-tablet: 640px;

$break-phone-large: 480px;


// FONTS


// font-family:'Trajan W01 Regular';
// font-family:'Trajan W01 Bd';
// font-family:'Frutiger Next W01 Light';
// font-family:'FrutigerNextW01-LightIt';
// font-family:'Frutiger Next W01 Bold';

$font-family-body:'Frutiger Next W01 Light';

$font-family-body-bold:'Frutiger Next W01 Bold';

$font-weight-body-bold: 400;

$font-family-headline-bold: 'Trajan W01 Regular';

$font-weight-headline-bold: 400;

$font-family-headline-thin: 'Trajan W01 Regular';

$font-weight-headline-thin: 200;

$font-family-accent:  'FrutigerNextW01-Light';

$font-family-header-title: 'Trajan W01 Regular';

$font-weight-header-title: 400;


$font-family-button: 'Frutiger Next W01 Bold';

$font-weight-button: 400;

$font-logo-one: 'Frutiger Next W01 Bold';


// COLORS TEXT AND BUTTONS


$font-color-headline-thin:#000;

$font-color-headline-bold:#ccc;

$font-color-body: #6E6B6B;

$font-color-button:#CCC;

$font-color-button-hover: #777;

$border-color-button:#ccc;

$border-color-button-hover: #777;

$font-color-nav-main: #555;

$font-color-nav-main-hover: #000;


// COLORS NAV

$font-color-body-link: #ccc;

$font-color-body-link-hover: #777;



$font-color-footer-link: #777;

$font-color-footer-link-hover: #888;


// COLORS BRANDING

$color-background-branding-1: #ccc	;

$color-background-branding-2: #ccc;

$color-background-branding-3: #ccc;

$color-font-branding: #777;
		
		