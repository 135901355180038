/* Scss Document */
.banner-featured-item {
	
	text-align:center;
	overflow:hidden;
	position:relative;
	clear:both;
	top:60px;
	
	margin-bottom: 120px;
	
	
	
	// set margins to cancel out padding in #results and make element full width 
	// set element heights
	
	
@media (max-width:480px) {
	
	padding-top:100%;	
	
}
	

@media (max-width:767px) {

	margin-left:-2%;
	margin-right:-2%;
	padding-top:70%;
	
}


@media (min-width:768px) {

	margin-left:-1%;
	margin-right:-1%;
	padding-top:55%;
	
}



@media (min-width:960px) {

	margin-left:-2%;
	margin-right:-2%;

	padding-top:45%;

}



@media (min-width:1100px) {

	margin-left:-12%;
	margin-right:-12%;

}



@media (min-width:1280px) {

	margin-left:-17%;
	margin-right:-17%;
	

}






@media (min-width:1365px) {


	margin-left:-33.25%;
	margin-right:-33.25%;


}





@media (min-width:1440px) {

	margin-left:-33.25%;
	margin-right:-33.25%;

}



@media (min-width:1800px) {

	margin-left:-46.5%;
	margin-right:-46.5%;
	
}



	
	
	
	
	//  end structural
	
	
	

		.button {
		
			display:block !important;
			width:20%;	
			position:relative;
			left:40%;
			right:40%;
			
		}
	
	
	
		.box-position {
	
				img {
					
					position:absolute;
					top:-300px;
					right:0;
					left:0;
					bottom:auto;	
					
				}
			
		  }
	  

		.banner-content {
			
			
			@include position-cover;
			margin:0;
			text-align:center;
			
			
		}

.accentblock-center {
	
	
	background-color:#000;
	display:inline-block;
	
	margin:15% auto 1em auto;
	
	padding:1em 2em 1em 2em;
	
	color:#fff;
	
	text-align:center;	
	
	font-family:$font-family-headline-thin;
	font-weight:$font-weight-headline-thin;

	line-height:1.25em;
	
	font-size:1.25em;
	
	@media (min-width:$break-tablet) {
		
		font-size:2em;
		
	}
	
}


}

#banner-page {

	position:relative;
	
	text-align:center;

	.banner-page {
				
		// set height sizes
		
		@media (max-width:480px)  {
		
			padding-top:100%;
			
		}

	@media (min-width:481px) and (max-width:767px) {
		
			padding-top:65%;
			
	}

	@media (min-width:768px) and (max-width:960px) {
		
			padding-top:65%;
			
	}
	
	
	
	@media (min-width:961px) and (max-width:1366px) {
		
			padding-top:35%;
			
	}
	
	@media (min-width:1367px)  {
		
			padding-top:28%;
			
	}
	
	// end height sizes
	
	
	.content {
		
		@include position-cover;
		
		.accent-title {
			
		
			margin-bottom:0;
			
			
		}
	}
	
	
	}
	
}

.banner-page-content {
	
		position:relative;
		margin: 4em auto 4em auto;
		
		text-align:center;	
		
		
		// set height sizes
		
		@media (max-width:480px)  {
		
			padding-top:90%;
			
		}

	@media (min-width:481px) and (max-width:767px) {
		
			padding-top:60%;
			
	}

	@media (min-width:768px) and (max-width:960px) {
		
			padding-top:45%;
			
	}
	
	
	
	@media (min-width:961px) and (max-width:1366px) {
		
			padding-top:35%;
			
	}
	
	@media (min-width:1367px)  {
		
			padding-top:28%;
			
	}
	
	// end height sizes
	
	
	.content {
	
		@include position-cover;
		
	}
	
	.accentblock-center {
	
	
	background-color:#000;
	display:inline-block;
	
	margin:15% auto 1em auto;
	
	padding:1em 2em 1em 2em;
	
	color:#fff;
	
	text-align:center;	
	
	// font-family:$font-family-headline-thin;
	// font-weight:$font-weight-headline-thin;
	font-family:$font-family-accent;
	
	font-size:1.25em;
	
	@media (min-width:$break-tablet) {
		
		font-size:2em;
		
	}
	
}

	.button {
		
			display:block !important;
			width:90%;	
			position:relative;
			left:5%;
			right:5%;
			line-height:1.25em;
			
			
			@media (min-width:$break-tablet) {
			
			display:block !important;
			width:50%;	
			position:relative;
			left:25%;
			right:25%;
			line-height:1.25em;	
				
			}
			
			@media (min-width:$break-desktop) {
			
			
				display:block !important;
				width:30%;	
				position:relative;
				left:35%;
				right:35%;
				line-height:1.25em;	
				
			}
			
		}
	
}





#banners-side {
	
	text-align:center;

	img {
		
		width:100%;
		height:auto;
		margin:.5em auto .5em auto;
		
	}
	
	.banner {
		
		margin:0 auto 2em auto;	
		
	}
	
}