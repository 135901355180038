/* Scss Document */

* {

	line-height:1.25em;	
	
}

h1, h2, h3, h4 {
	
	
	
	font: {
		
		family: $font-family-headline-thin;	
		weight: $font-weight-headline-thin;
	
	}
	
	&.bold {
		
		font: {
			
		family: $font-family-headline-bold;	
		weight: $font-weight-headline-bold;
		
		}
		
	}
	
}


h1 {

	font-size:3em;	
	
}

h2 {

	font-size:2em;	
	
}

h3 {

	font-size:1.75em;	
	
}

body {
	
	font-family:$font-family-body;
	
}

strong {
	
	font-weight:$font-weight-body-bold;
	
}


p {

	line-height:1.75em;
	color: $font-color-body;	
	
}


.accent {
	
	font-family: 'Trajan W01 Regular'; // $font-family-accent;
	// font-style:italic;
	
}

.light {
	
	font-weight:$font-weight-headline-thin;	
	line-height:1.25em;

}

.dropcap {
	
	
	p {
	
	&:first-child {
	
	&:first-of-type::first-letter { 

		float: left;
		
		color: $font-color-body-link;

		line-height: 1em;
		padding: 4px 8px 0 3px; 

		font: {
			size: 6em; 
			family: $font-family-headline-thin;
			weight: $font-weight-headline-thin; 
			}
		}
		
	}
		
	}
	
}


.section-copy {
	
	padding:4em 2em 2em 2em;	
	
	p {
	
		margin:0 0 1em 0;	
		
	}
	
}



.section-copy-top {
	
	padding:0em 2em 2em 2em;	
	
	margin:0 auto 0 auto;
	
	p {
	
		margin:0 0 1em 0;	
		
	}
	
	border-bottom: dotted thin #ccc;
	
	@media (min-width: $break-desktop) {
		
		width:80%;	
		
	}
	
}



article {
	

	margin:.75em .5em 1.25em .5em;
	
	padding:1em;	
	
	
}




.box-overlay-content {
	
	.comment {
		
		display:block;
		margin:0 0 1em 0;
		color:#fff;
	
		font:{
			
			family:$font-family-headline-bold;
			weight:$font-weight-headline-bold;
			size:.875em;	
			
		}
		
	}


	.name {
		
		display:block;
		margin:0 0 .25em 0;
		color:#fff;
	
		line-height:1.25em;
		
		font:{
			
			family:$font-family-headline-bold;
			weight:$font-weight-headline-bold;
			size:1.75em;	
			
		}
		
	}

	
	p {
		
		color:#fff;
		line-height:1.25em;
		
		margin:0 0 .5em 0;
		
	}
	
	.position {
		
		display:block;
		color:#fff;
		
		font: {
			
			style:italic;
			weight:$font-weight-headline-bold;
			size:.875em;
			
		}
		
		line-height:1.5em;		
		
	}
	
}

.row-text {

	display:block;	
	line-height:1.25em;
	
}

#blocks-contact {
	
		h3 {
			
			text-align:center;
			margin:1em auto 2em auto;
			line-height:1.25em;
			
			font: {
				
					family:$font-family-headline-bold;
					weight:$font-weight-headline-bold;
					
			}
			
			color:$color-font-branding;
			
		}
}

#contact-info {
	
	.row-text {
		
		margin:.5em;
		
		
	}
	
	
	.row-title {
	
			display:block;
	
			margin:1.5em .5em .5em .5em;
	
			color:#000;
	
			font: {
				
				size:1.25em;
				weight:600;
				
			}
			
			&:first-child {
				
				margin:0 .5em 1em .5em;
				
				
				
			}
		
	}
	
}

.h3 {
	line-height: 1.25em;
	margin:0 0 1em 0;
}