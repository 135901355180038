/* Scss Document */

// GLOBALS

body {
	
	overflow-x:hidden;
	
}

// CLEAR FIX

.cf:before, .cf:after , .row:before, .row:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after, .row:after {
    clear: both;
}

.cf, .row {
    *zoom: 1;
	clear:both;
}

.clear {
	
	clear:both;
	
}

.row {
	
	width:100%;
	clear:both;
	
}


.text-left {
	
	text-align:left !important;
	
}

.center {

	text-align:center;	
	
}


.left {
	
	float:left;
	
}


.right {

	float:right;	
	
}

.row-margin {

	margin:1em auto 1em auto;	
	
}


.block-link {
	
	display:block;	
	
}

.img-width-full {
	
	img {
		
		width:100%;
		height:auto;
		
		
	}
	
}

img.img-width-full {
	
	width:100%;
	height:auto;
	margin:0 auto 1.5em auto;	
}





.img-width-third {
	
	img {
		
		width:100%;
		height:auto;
		
		@media (min-width:$break-tablet) {
			
			width:33%;
			float:left;
			margin: 0 1em 0 0;	
			
		}
		
		
	}
	
}

img.img-width-third {
	
	width:100%;
	height:auto;
	margin:0 auto 1.5em auto;
	
	@media (min-width:$break-tablet) {
			
			width:33%;
			float:left;
			margin: 0 1em 0 0;	
			
			
		}	
}


.img-width-eighth {
	
	img{
	
	width:100%;
	height:auto;
	margin:0 auto 1.5em auto;
	
		@media (min-width:$break-tablet) {
				
				width:12%;
				float:left;
				margin: 0 1em 0 0;	
				
				
			}	
		
	}
}



.img-width-quarter {
	
	img {
		
		width:100%;
		height:auto;
		
		@media (min-width:$break-tablet) {
			
			width:25%;
			float:left;
			margin: 0 1em 0 0;	
			
		}
		
		
	}
	
}




.width-page {
	
	max-width:960px;
	margin:0 auto 0 auto;
	position:relative;
	
}




.iframe-box {
	
	position:relative;
	// padding-bottom:56.25%;
	
	padding-bottom: 100%;
	padding-top:30px;
	height:0;
	overflow:hidden;
	
	
	iframe, object, embed {
		
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%
		
		}

}


.overlay {

	display:none;	
	
}

.overlay-open {

	display:block;
	position:fixed !important;
	top:0;
	right:-5000px;
	bottom:-5000px;
	left:-5000px;
	background-color:#000;
	opacity:.7;
	z-index:11;	
	
}

.overlay-search {

	display:none;	
	
}

.overlay-search.open {

	display:block;
	position:fixed !important;
	top:0;
	right:-5000px;
	bottom:-5000px;
	left:-5000px;
	background-color:#000;
	opacity:.7;
	z-index:6;	
	
}

header {
	
	
	
	position:fixed;
	
	
	top:0;
	right:0;
	left:0;
	height:125px;
	
	z-index:10;
	
	@media (min-width:$break-tablet) {
		
		position:fixed;
		
		height:150px;
		
		// position:absolute;
		
	}
	
	.content {
		
		height:125px;
		
		@media (min-width:$break-tablet) {
			
			height:150px;
			
		}
		
	}
	
}


footer {
	
	postion:relative;
	
	
	@media (min-width:$break-tablet) and (max-width:$break-desktop) {
	
	.col {
		
	padding:1em 3em 1em 3em;	
		
	}
	
	}
		
}

/** columns **/


.box-position {

	@include position-cover;	
	
}

.box-height {

	overflow:hidden;	
	
	position:relative;
	
}



.base-two {
	
		.col {
		
			width:100%;
			
				@media (min-width:$break-tablet)  {
					
				width:50%;
				float:left;	
					
				}
		
				
				
				// col end styles
				
				
				.box-height {
					
					
				
					padding-top:100%;
				
					@media (min-width:$break-tablet)  {
					
					padding-top:95%;	
						
					}	
				
				
						
			
				}
				
				// end box-height styles
			
			}
	
	}






.base-three {
	
		.col {
		
			width:100%;
			
				@media (min-width:$break-tablet)  {
					
				width:33.3%;
				float:left;	
					
				}
		
				
				
				// col end styles
				
				
				.box-height {
					
				
					padding-top:100%;
				
					@media (min-width:$break-tablet)  {
					
					 padding-top:95%;	
					
					//padding-top:100%;	
						
					}	
				
				
						
			
				}
				
				// end box-height styles
			
			}
			
			
			.col-two {
				
				@media (min-width:$break-tablet)  {
					
				width:66.6%;
				float:left;	
					
				}
				
			}
	
	}




.base-four {
	
		.col {
		
			width:100%;
			
				@media (min-width:$break-tablet) and (max-width:$break-desktop) {
					
				width:50%;
				float:left;	
				
				
					
				}
		
				@media (min-width:$break-desktop) {
					
				width:25%;
				float:left;	
					
				}
				
				// col end styles
				
				
				.box-height {
					
				
					padding-top:100%;
				
					@media (min-width:$break-tablet) {
					
					padding-top:95%;	
						
					}	
				
				
					@media (min-width:$break-desktop) {
					
					padding-top:95%;	
						
					}		
			
				}
				
				// end box-height styles
			
			}
			
			
	}



// special sections


.featured-article {
	
	position:relative;
	
	margin:1em auto 2em auto;
		
	@media (min-width:$break-tablet) and (max-width:$break-desktop) {
		
	padding: 70% 0 0 0;		
	
	}
	
	@media (min-width:$break-desktop) {
		
	padding: 42% 0 0 0;		
		
	}	
		
	.featured-img-box {
		
		padding:100% 0 0 0;
		
		position:relative;
		
		@media (min-width:$break-tablet) and (max-width:$break-desktop) {
			
			
			padding:70% 0 0 0;
			
			width:70%;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			overflow:hidden;	
			
		}
		
		@media (min-width:$break-desktop) {
			
			
			padding:40% 0 0 0;
			
			width:70%;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			overflow:hidden;	
			
			
			
		}
		
		
		
			img {
					
					
					width:auto !important;
					height:500px !important;
					
					 @include position-cover;
					
					@media (min-width:$break-tablet) and (max-width:$break-desktop) {
			
						width:auto !important;
						height:600px  !important;
					}
					
					@media (min-width:$break-desktop) {
			
						width:100% !important;
						height:auto !important;	
						
					}					
				
			     }
		
		}

	.featured-description-box {
		
		width:100%;
		
		padding:1.25em;
		
		position:relative;
		
		@media (min-width:$break-tablet) {
			
			position:absolute;
			top:0;
			bottom:0;
			right:0;
			overflow:hidden;	
		
			width:28%;
			
		}		
		
	}
	
	
}

// box overlay global


.box-overlay {

	display:none;	
	
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	
	background-color:#blue;
	
	overflow:hidden;
}

.box-overlay-content {
	
	position:absolute;
	top:60%;
	right:0;
	bottom:0;
	left:0;
	padding:1em;
	
	

	
}



