/* Scss Document */


/* header styles not common for all sites */

.lable {
	
	color:#fff;
	
}

.back-white-opaqe {
	
		background-color:#fff;
	
}

.well {
	
	@include rounded(.5em);
	
	background-color:#EBEBEB;
	padding:1em;
	
	border:solid thin #96C4D7;
	
	margin:.5em auto 2em auto;	
	
}
.padding {
	
	padding:1.5em;
	
}

.shadowgrade-top {

	
	@include position-top;
	
	@include background-x(url(../img/shadowgrade-top.png));
	
	color:#fff;
	
	a {
	
		color:#fff;	
		
		line-height:1.25em;
		
	}
	
}

.shadowgrade-bottom {

	
	@include position-bottom;
	
	background: {
	
		image:	url(../img/shadowgrade-bottom.png);
		size:100% 100%;
		position: left bottom;
		
	}
	
	
	color:#fff;
	
	a {
	
		color:#fff;	
		
	}
	
}


hr.shadow-bottom {
	
	display:block;
	padding:2em 0 0 0;
	 @include background-cover(url(../img/shadow-bottom.png));
	border:none;
	width:100%;
	
}

hr {
	
	border-top:none;
	border-right:none;
	border-bottom:solid thin #C8C7C7;
	border-left:none;
	
	// here
	
	// here too
	
}

.text-left {
	
	text-align:left;	
	
}

.margin-bottom {

	margin:0 0 3em 0;	
	
}

.list-center, .list-left {
	

	list-style:none;
	display:block;
	
	
	margin: 1em 0 2em 1em;
	
	li {
		
		display:block;
		padding:.75em .5em .75em .5em;
		
		
		font: {
			
			family:$font-family-headline-bold;
			weight:$font-weight-headline-bold;	
			
		}
		
		color: $font-color-body-link;
		
	}
	
	
}

.list-center {
	
		text-align:center;
		
}

.list-left {

text-align:left;

}

.back-trans-accent {

	background: rgb(00, 000, 000);
    background: rgba(00, 000,000, 0.7);
}


 .blocks-two {
	 
	 margin:1em auto 1em auto;
	 
	img {
		
		width:98%;
		height:auto;
		
		
		
		
		@media (min-width:$break-tablet) {
			
			width:48.5%;
			float:left;
			
			&:first-child {
				
				margin:1% 1% 1% 0;
				
			}	
			
			
			&:last-child {
			
				margin:1% 0 1% 1%;	
				
			}
			 
		}
		
	}
	 
 }


header {
	
	
	background: {
		
		image:url(../img/shadowgrade-top.png);
		size:100% 100%;
		repeat:no-repeat;
		
	}
	
	
	
	//  logo and site title
		
	.logo {
	
		position:absolute;
		top:5px;
		left:0;
		width:125px;
		height:125px;
		z-index:4;
		
		opacity:1;
		
		transition:all .5s;
		
		@media (min-width:$break-tablet) {
			
			top:25px;
			width:535px;
			height:150px;
			
		}

	}
	
	.button-logo svg, .button-logo img {

		display:block;
		width:324px;
		height:100px;
		float:left;	
	
	}
	
	.title {
		
	display:none;	

	@media (min-width:$break-tablet) {

	display:block;
	float:right;
	width:410px;
	height:100px;
	color:#fff;	
	text-align:center;
	
	font: {
	
	// family: $font-family-header-title;
	// weight:$font-weight-header-title;
	
	family: $font-logo-one;
	// weight:$font-weight-headline-thin;
	
	}
	
	text-transform:uppercase;
	letter-spacing:1px;
	
	}
	
		.line-one {
	
		display:block;
		
		font-size:3em;
		
		padding:1.125em 0 .375em 0;
		
		
		// font-size:1.25em;	
		// border-bottom:solid thin #fff;
		// padding:2.75em 0 .375em 0;
		// margin:0 .625em .25em .75em;
		
		}
	
		.line-two {
		
			display:block;
			font-size:.75em;	
			
		}
	
	}
	
	// main nav button
	
	.button-menu {
	
		position:absolute;
		top:25px;
		right:0;
		display:block;
		width:68px;
		height:68px;	
		z-index:19 !important;
		cursor:pointer;
		
		background: {
			
			image:url(../svg/icon-menu.svg);
			position: left top;
			repeat:no-repeat;	
			
		}
		
		@media (min-width:$break-tablet) {
		
		top:50px;	
			
		}
		
	
		&.open {
			
			background: {
			
			image:url(../svg/icon-menu.svg);
			position: left top;
			repeat:no-repeat;	
			
		}
			
		}
		
	
	}
	
}

//

.nav-main-open {
	
	
	margin:0 6px 0 6px;
	
	padding:5% 5% 0 %5;
		
		nav {
			
			
			
				a {
				
				display:block;
				line-height:2em;
				text-align:center;
				
				line-height:2em;
				
				color:$font-color-nav-main !important;
				
				font:{
					
					size:1.5em;
					family:$font-family-headline-thin;
					weight:$font-weight-headline-thin;
					
					
				}
				
				&:hover {
				
					color:$font-color-nav-main-hover !important;	
					
				}
				
				@media (min-width:$break-tablet) {
					
					text-align:left;
					font-size:2.75em;
					line-height:1.5em;
				}
				
				
			}	
			
			//
				
			@media (min-width:$break-tablet) and (max-width:$break-desktop) {
					
				padding:5% 0 10% 5%;
					
			}
				
			@media (min-width:$break-desktop) {
					
				padding:5% 0 10% 10%;
					
			}
				
			
			
	}
		
		
		
		.contact {
			
			padding: 0 0 0 3%;
			
			border-left:solid thin #C5C5C5;
			
			.branding {
				
				
			img,  svg {
			
					width:85%;
					height:auto;
					margin:10% auto 1em  5% ;
					
				}
				
			}
			
			.address {
				
				display:block;
				position:relative;
				
				margin:1em 10% 1em 10%;
				
				color:#ACABAB;
				
				line-height:1.25em;
				
				font-family:$font-family-headline-bold;
				
			}
			
			.tel {
				
				display:block;
				
				font:{
					family:$font-family-headline-bold;
					size:24px;
				}
				
				color:#717171;	
				margin:1em 10% 1em 10%;
			}
			
			.button {
				
				margin:1em auto auto 10%;
				
			}
			
		}
	
}


//

footer {
	
	
	background-color:$color-background-branding-1;
	padding:0 0 3em 0;
	
	
	
	.col {
		
		text-align:center;
		
		@media (min-width:$break-tablet) {
		
			text-align:left;
			
			height:260px;	
			
		}



		@media (min-width:$break-desktop) {
		
			height:auto;
			
		}
		
		
	.social {
		
		
			text-align:left;
			padding:0 0 0 2em;
			
			@media (min-width:$break-tablet) {
				
			padding:0;	
				
			}
		
		
	}
		
		
	}
	
	.content {
		
		position:relative;
		
		max-width:960px;
		
		margin:0 auto 0 auto;
		
		padding:4em 0 1em 0;
		
		text-align:center;
		
		color:#fff;
		
		background: {
			
			image:url(../img/shadow-bottom.png);
			position:center top;
			size:100% 30px;
			repeat:no-repeat;	
			
		}
		
		.logo svg, .logo img {
			
			width:100px;
			height:100px;
			
			@include position-center (-50px auto 0 -50px) ;

		}
		
	}
	
	
		.cta {
			
			font: {
			
			family:$font-family-headline-bold;
			weight:$font-weight-headline-bold;
			size:2.5em;
			
			}
			
			display:block;
			
			text-transform:uppercase;
			line-height:1.5em;
			
			color:#777;
			
		}
		
		
		
		.title {
			
			color:#000;	
			
		}
		
		
		.tel {
			
			font:{
			
			family:$font-family-headline-bold;
			weight:$font-weight-headline-bold;
			size:1.75em;
			
			}
			
			display:block;
			
			text-transform:uppercase;
			line-height:1.5em;
			margin:0 0 2em 0;
			
			color:#000;
		}
		
		
		.branding svg, .branding img {
			
			width:96%;
			height:auto;	
			
			
			
			
		}
		
		.address {
		
			display:block;
			font-size:.75em;
			color:#777;
			text-transform:uppercase;	
			margin:.5em 0 .5em 0;
			
			text-align:center !important;
			
			@media (min-width:$break-desktop) {
			
				text-align:left ;	
				
			}
			
		}
		
	
}




#blocks-home {
	
	padding:3em 0 0 0;
	
	.col {
		
		// give whitespace to column blocks
		
		width: 98%;
		margin:1%;
		
		@media (min-width:$break-tablet) and (max-width:$break-desktop) {
			
		width: 48%;
		margin:1% 1% 3% 1%;
			
		}
		
		@media (min-width:$break-desktop) {
			
		width: 23%;
		margin:1%;
			
		}
		
		
		
		
	}
	
}

#blocks-home, #blocks-about, #blocks-what {
	
	.box-position {
		
		
		
	//	&:hover{
		
	//	@include shadow-box(0, 0, 3px, #888);	
			
	//	}
	
	a {
		
		@include position-cover;
	
	}
	img {
		width:100%;
		height:auto;
		@include position-cover;
		
	}
	
	
	h2 {
		
		font-size: 24px;
		line-height:50px;
		color:#fff;
		background-color:$color-background-branding-1;	
		text-align:center;
		
		 @include position-top;
		
	 }
	 
	 hr {
		
		position:absolute;	
		top:42px;
		right:0;
		left:0;
		
	}
	
	}
	
}
	


h3.accent {
	
	display:inline-block;
	color:#9A9A9A;
	text-align:center;
	
	
	margin-left:auto;
	margin-right: auto;
	margin-bottom:-.875em;
	font-size:24px;

	line-height:1.25em;

    text-shadow:
    -2px -2px 0 #fff,  
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
     2px 2px 0 #fff;

	
}

.divider-v {

	padding:2em 0 3em 0;	
	
}

#updates {
	
	.box-position {
	
		img {
		width:100%;
		height:auto;
		@include position-cover;
		
		
		
		}
		
	}
	
	
	
	.col {
		
		width:98%;
		margin:1%;
		
		@media (min-width:$break-tablet) {
			
			width:48%;
			margin:1%;	
			
		}
		
		
		.box-height {
					
				
					padding-top:64%;
				
					@media (min-width:$break-tablet)  {
					
					padding-top:60%;	
						
					}	
		
		}
	}
	
	
	h2 {
		
		color:#fff;	
		
		font-size:18px;
		
		
		a {
		
			color:#fff;	
			display:block;
			padding:.75em .75em 3em .75em;
			
		}
		
	}
	
	
	
		
		.button-small {
			
			display:block;
			position:absolute;
			left:1em;
			bottom:1em
		}
	
}

#reviews {
	
	margin: 0 auto 2em auto;
	
	.s2 {
	
		margin: 0 auto 2em auto;
		
	}
	
	blockquote {
		
		
		font: {
		
		family:$font-family-headline-bold;
		weight:$font-weight-headline-bold;
		size:1.5em;
		
		}
		
		line-height:1.5em;
		
		padding:1em 15% 0 15%;
		
	}
	
}

.color-accent-light {
	
	background-color:$color-background-branding-2;
	color:#000;
	
	h3 {
	
		line-height:1.25em;
		
	}
	
	p {
	
		margin:1em 0 1em 0;	
		
	}
	
}


.featured-img-box {


h2 {
		
		color:#fff;	
		line-height:1.5em;
				
		
		a {
		
			color:#fff;	
			display:block;
			padding:.75em .75em 3em .75em;
			
		}
		
	}


	
.shadowgrade-bottom  {
	
	 display:none;
	
	color:#fff;
	font-size:1.5em;
	line-height:1.25em;
	padding:3em 1em 1.5em 1em;
	
	@media (min-width:$break-tablet) {
		
	display:block !important;
		
	}
	
	
	}
	
}


#featuredblockdesign {
	
	margin:0 auto 5em auto;	
	
}


#results {
	

// set page widths
	
@media (max-width:767px) {
	
	padding:0 2% 0 2%;
	
}


@media (min-width:768px) {
	
	padding:0 1% 0 1%;
	
}


@media (min-width:960px) {
	
	padding:0 2% 0 2%;
	
}


@media (min-width:1100px) {
	
	padding:0 10% 0 10%;	
	
}


@media (min-width:1280px) {

	padding:0 12% 0 12%;

}




@media (min-width:1365px) {
	
	padding:0 16% 0 16%;
	
}


@media (min-width:1440px) {
	
	padding:0 16% 0 16%;
	
}


@media (min-width:1800px) {

	padding:0 24% 0 24%;

}


// end sizes




	.col {
		
			margin:1%;
			width:98%;
			
			
			cursor:pointer;
			
			@media (min-width:$break-tablet) {
				
			margin:.75%;
			width:31.5%;
				
			}
		
			.box-position {
				
			
				img {
					
					width:100%;
					height:auto;	
					
				}
				
				
				.shadowgrade-top {
					
						font:{
						
							family:$font-family-headline-bold;
							weight:$font-weight-headline-bold;
							size:1em	
							
						}
						
						
						line-height:1.375em;
						text-align:center;
						padding:.75em 0 3em 0;
						

						@media (min-width:$break-tablet) {
							
							font-size:1.25em;
							
						}

						
						@media (min-width:$break-desktop) {
							
							font-size:1.875em;
							
						}
					
				}
				
			}
		
	}
	
	
}

#browser {
	
	position:absolute;
	bottom: 0;
	width:90%;
	left:10%;
	right:10%;
	
	padding:.5em ;
	
	@media (min-width: 1366px) {
		
		width:50%;
		left:25%;
		right:25%;
		
	}
	
	
	z-index:100;
	
	background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
	
	
		.browser-content {
			
			background-color:#fff;
			margin:1em;
			
		}
	
}






#button-search {
	
	text-align:center;
	position:absolute;
	bottom: 0;
	width:98%;
	left:1%;
	right:1%;
	
	padding:.5em ;
	
	@media (min-width:$break-tablet) {
		
	width:50%;
	left:25%;
	right:25%;	
		
	}
	
	@media (min-width:$break-desktop) {
		
	width:30%;
	left:35%;
	right:35%;		
		
	}


	@media (min-width: 1366px) {
		
		width:30%;
		left:35%;
		right:35%;
		
	}
	
	
	z-index:5;
	
	background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
	
	
	
	
	
	
		.browser-content {
			
			background-color:#fff;
			margin:1em;
			
		}
	
}







.browser-tabs {
	
	display:none;	
	
}

.browser-tabs.open {
	
	display:block;	
	background-color:#fff;
	padding:1em;
	
}

.rooms {
	
	
	a {
		
		display:block;
		
		text-align:center;
		
		line-height:2em;
		
		padding:0 1em 0 1em;
		
		
		@media (min-width:$break-tablet) {
			
			
		text-align:left;	
			
		width:20%;
		
		float:left;
			
		}
		
		
		
		font: {
			
			family:$font-family-accent;	
			size:1.25em;
			
		}
		
		&:hover {
			
			background-color:#E0E0E0;
			
		}
		
	}
	
	
}

.styles {
	
a {
		
		display:block;
		
		text-align:center;
		
		
		@media (min-width:$break-tablet) {
			
		text-align:left;
			
		width:30%;
		
		float:left;	
			
		}
		
		line-height:2em;
		
		padding:0 1em 0 1em;
		
		font: {
			
			family:$font-family-accent;	
			size:1.25em;
			
		}
		
		&:hover {
			
			background-color:#E0E0E0;
			
		}
		
	}
	
}


#browser-box {
	
		display:none;
	
}



#browser-box.open {
	
		display:block;
		position:absolute;
		top:150px;
		right:1em;
		left:1em;
		z-index:7;
		
		@media (min-width:$break-tablet) {
		
		top:175px;	
		right:5%;
		left:5%;
			
		}

		@media (min-width:$break-desktop) {
		top:175px;	
		right:20%;
		left:20%
			
		}

	
}



.accent-title {
	
		position:relative;

		z-index:2;
		
		margin:-45px auto 0 auto;
		
		display:inline-block;
		text-align:center;
		background-color:#000;
		color:#fff;	
		
		padding:1em 3em 1em 3em;	
		
		line-height:1.25em;
		
		font: {
		family:$font-family-accent;
		style:italic;
		size:1.75em;
		
		
		}
		
		.light {
		
				font: {
		
				family:$font-family-headline-thin;	
				weight:$font-weight-headline-thin;
				style:normal;
				
				}
	
		}
	
}




#gallery-mini {
	
	img {
		
		width:48%;
		height:auto;
		float:left;
		margin:0 1% 1% 1%;	
		
	}
	
	.button-small {
	
		margin:2em auto !important;	
		
	}
	
}

#photo-details {

	.box-height {
            
        .box-position {
			
			img {
			
				width:100%;
				height:auto;	
				
			}
			
		}
		
		margin-bottom:2em;
		
	}

	.button-small {
	
		position:absolute;
		bottom:2em;
		left:1em;	
		
	}
	
}

#details {
	
	h3 {
	
		font:{
			
			family:$font-family-headline-bold !important;
			weight:$font-family-headline-bold !important;	
			
		}
		
		text-transform:uppercase;
		
		margin:0 0 1em 0;
		
		line-height:1.25em
	}

	strong {
	
		display:block;
		text-transform:uppercase;	
		margin:.5em 0 1em 0;
	}
	
	
		padding:4em 0 0 0;	
		
		margin:0 auto 8em auto;
	
	.col {
	
		padding:0 1em 0 1em;	
		
	}
	
	p {
		
		margin-bottom:2em;	
		
	}
	
	.pagination {
		
		margin-bottom:1.5em !important;	
		
	}
	
}


// set page banner backgrounds


.about {
	
	background-image:url('/content/img/banner-siesta-key-home.jpg');
			
	@include banner-background ;
	
}

.projects {
	
	background-image:url('/content/img/banner-mixed-use.jpg');
			
	@include banner-background ;
	
}


.office {
	
	background-image:url('/content/img/banner-siesta-key-home.jpg');
		
	
	@include banner-background ;
	
}

.kitchen {
	
	background-image:url('/content/img/banner-siesta-key-home.jpg');
		
	@include banner-background ;	
	
}


#blocks-about, #blocks-what {
	
	
	.col {
		
	padding:.5%;	
	
	.box-height {
					
				//	overflow:hidden;
					
					
					
					
					padding-top:75%;
					
					
					@media (min-width:$break-tablet)  {
					
					
					padding-top:70%;	
						
					}	
		
				}
	
	
	.box-position {
		
		img {
			
			width:100%;
			height:auto;	
			
		}
		
		svg {
			
			display:block;
			margin:30% auto auto auto;
			width:80%;
			height:auto;
			
		}
		
	}
	
	}
	
}


#blocks-videos {
	

	.col {
		
			padding:1.5%;	
			
			p {
			
				margin:.75em .5em 1em .5em;	
				
			}
	}
	
	
}


#featured-video {
	
	margin-bottom:3em;
	
	
	h2 {
		
		a {
		
			display:block;
			margin:.75em .5em 1em .5em;	
			line-height:1.25em;
			
		}
		
	}
	
	
	.col {
		
	
	
		@media (min-width:$break-tablet) {
			
			width:90%;
			margin:0 auto 0 auto;
			
			
		}
		
		@media (min-width:$break-desktop) {
			
			width:80%;
			margin:0 auto 0 auto;
			
			
		}
	
		
	}
	
}

.stars {
	
	background-color:#E0E0E0;
	
}

#blocks-articles, #article-details {
	
	
	margin-bottom:3em;
	
	
	h3 {
		
		line-height:1.25em;
		margin:.5em 0 1em 0;	
		
	}
	
	
	 h2 {
		
		text-align:center;
		
		font: {
			weight:$font-weight-headline-bold;
			size:1.75em;
		}
		
		line-height:1.25em;
	}
	
	h1 {
		
		font: {
			
			size:2em;	
			weight:$font-weight-headline-bold;	
			family:$font-family-headline-bold;
			
			}
			
			line-height:1.25em;
			
			margin: 0 0 1em 0;
		
		a {
			
			color:$font-color-body-link;
			
			&:hover {
				
				color:$font-color-body-link-hover;	
				
			}
			
		}
		
	}

	.col {
		
	padding:2%;	
	
		background-color:$color-background-branding-2;
		
	}
	
	
	.col-two {
		
	padding:2%;		
		
	}
	
	
	article  {
		
		
		border-bottom:dotted thin #ccc;
		
		
		&:first-child {
			
			margin:0m .5em 1.25em .5em;
	
			padding:0 1em 1em 1em;	
	
			
		}
		
		
		&:last-child {
			
			border-bottom:none;	
			
		}
		
		
		h2 {
			
			margin:.75em .5em 1em .5em;
			text-align:left;
			
			a {
				
				display:block;
				
				line-height:1.25em;	
				
			}
			
		}
		
	}
	
	
}

#blocks-team.base-three {
	
	
	
		
			.col {
			
				width:70%;
				
				margin:1em 15% 1em 15%;
				
					@media (min-width:$break-tablet)  {
						
					width:28%;
					
					margin:2.5%;
					
					float:left;	
						
					}

				@media (min-width:$break-desktop)  {
						
					width:25%;
					
					margin:3.5%;
					
					float:left;	
						
					}

					
					
					background-color:#ccc;
		
			}
			
		
	.box-height {
		
	
	padding-top:100%;
				
		@media (min-width:$break-tablet) {
					
			padding-top:100%;	
						
		}	
				
				
		@media (min-width:$break-desktop) {
					
			padding-top:100%;	
						
		}	
		
		
	}
	
	.box-overlay-content {
		
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		padding:1em;			
		
	}
	
}

#reviews {
	
	.col {
		
		text-align:center;
		
		margin:1em 15% 1em 15%;	
		
		padding:1em;
		
		&:first-child {
			
			margin:0 15% 1em 15%;	
			
		}
		
		&:last-child {
			
			margin:1em 15% 4em 15%;	
			
		}
		
		
		p {
			
			margin: .5em 0 1.5em 0;	
			
		}
		
		.row-text {

			margin:.5em 0 .5em 0;
			
			color:#89B1C3;
			
			font: {
				
				style:italic;
				
			}
			
			strong {
			
				
				display: inline-block;
				
				padding: 0 0 0 .5em;
				
				color: $font-color-body-link;
				
				font: {
				
					style: normal;	
					
				}
				
			}
	
		}
	
	}
	
}

#blocks-recognition {
	
	margin: 1em 0 8em 0;	
	
	.col {
		
		padding:1.5em;
		
		img {
			
			width:100%;
			height:auto;
			
		}
		
	}
	
}

#blocks-profile {
	
	margin: 0 auto 8em auto;
	
	.col {

			@media (min-width:$break-tablet) {
			
				margin:0 2em 2em 0;	
				
			}
			
			
			.box-height {
					
				
					padding-top:100%;
				
					@media (min-width:$break-tablet)  {
										
					padding-top:120%;	
						
					}	

			}
				
		
	}
	
	
		.box-position {
		
			.comment {
			
				position:absolute;
				
				left:0;
				right:0;
				bottom:0;	
				
				color:#fff;
				
				.row-text {
					
					line-height:1.25;
					padding:.25em .5em .25em 1.5em;
					
					font-weight:bold;
					
					
					&:last-child {
						
						font: {
							
							style:italic;
							weight:normal;
							
						}
						
					}
					
				}
			}
			
		}
	
	
}


#blocks-articles {
	
		article {
			
			 img {
				 
				 width:100%;
				 height:auto;
				 margin: 1em 0 1.5em 0;
			 }
		}
}


#blocks-contact {
	
		.base-two {
		
		.col {
			
			paddng:.5em 2% .5em 2% !important;
			
			}
			
		}
		
		
		
	
}

#blocks-maps {
	
		margin:2em auto 8em auto;	
		
		
		
		
		.col {
			
			
			padding:1.5em;
			
			
			h2 {
				
				margin:.5em 0 1em 0;
				line-height:1.25em;
				
				
				@media (min-width:$break-tablet) and (max-width:$break-desktop) {
					
				font-size:1.5em;	
					
				}
				
			}
			
		}
	
}

#contact-info { 

	padding:1.5em;

	.lable {
		
		display:inline-block;
		width:50px;	
	}

}

#contact-form {
	
	padding:1.5em;
	
	> div {
		
		background-color:#fff;
		padding:1.5em;
	
	}
}


#contact-banner {
	
	.img-width-third {
	
	img {
		
		width:100%;
		height:auto;
		
		@media (min-width:$break-tablet) {
			
			width:33%;
			float:left;
			margin: 0 3em 0 0;	
			
		}
		
		
	}
	
}

}
 
#brochure-link {
	
	a {
		
		color:#fff;
		
		font: {
			
				family:$font-family-headline-bold;
				weight:$font-weight-headline-bold;
				
		}
		
		span {
				position:relative;
				top:1em;
			
		}
		
	}
}


.color {

	color:	$font-color-body-link;
	
}

#sitemap {
	
	margin-bottom:8em;
	
	h2 {
	
		font: {
			
				family:$font-family-headline-bold;
				weight:$font-weight-headline-bold;
				size:2em;	
		
			}
			
			line-height:1.25em;
			
	}
	
	a {
		
		display:block;
		padding:.5em;
		margin:.25em;
		
	}
	
	.col {
		
			a {
				
				font-size:1.75em;
				@include rounded(.25em);
				background-color:#E3E3E3;
			}
			
	}
	
	
	
}


.mapbx {
	
	margin:1.5em;	
	
}

img.full {
	

	width:100%;
	height:auto;	
}

#serviceblocks {
	
margin:2em auto 2en auto;
		
.col > div { 

	margin: 0 .5em .5em .5em;

	text-align:center;	
	
	padding: 1em;
	
	background-color: #364866;
	
	color:#fff;
	
	
		@media screen and (min-width:768px) {
		
		height: 22rem;	
		
		}
		
		@media screen and (min-width:960px) {
		
		height: 22rem;
					
		
		}
	
	}
			
			
	h3 {
				
		text-align:center !important;	
		
		margin: 0 auto .75em auto;
		
		padding: .75em 0 .25em 0;
	
	}
			
			
	p {
	
		text-align:center !important;
		
		color: #B3CAE6;
	
	}
			
	
}

h3 {
	
	&.title {
	
		font-size: .875em;
		display:block;
		
		margin:.875em auto .75em auto;	
		
	}
	
}

#team > div > div {
	
	padding:.5em;
	
}

#team {
	
	margin:2em auto 2em auto;
	
}