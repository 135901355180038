/* Scss Document */


/* set styles for home banner swiper */

#banner, #gallery {

position: relative;


.swiper-container {
    width: 100%;
	background-color:#ccc;
	text-align:center;
}   

.slide-home {

background-size: auto 100%;
background-position:center;
	
}


.slide-container {
max-width:960px;
margin:0 auto 0 auto;


	@media (max-width:480px)  {
		
			padding-top:100%;
			
	}

	@media (min-width:481px) and (max-width:767px) {
		
			padding-top:70%;
			
	}

	@media (min-width:768px) and (max-width:960px) {
		
			padding-top:55%;
			
	}
	
	
	
	@media (min-width:961px) and (max-width:1366px) {
		
			padding-top:45%;
			
	}
	
	@media (min-width:1367px)  {
		
			padding-top:38%;
			
	}

}

/* within the slide container */

.slide-container .content {
	
	@include position-cover;
	
		h1, h2 {
		
		position:absolute;
		left:0;
		right:0;
		bottom:0;
		text-align:center;
		color:#fff;	
		
		padding: 4em 0 2em 0;
		
		font-weight:400;
		
		line-height:1.25em;
		
	background: {
		
			image:url(../img/shadowgrade-bottom.png);
			repeat:repeat-x;
			position:left bottom;
			size:auto 100%;
	
		}
		
	}
	
	
	h1.accent, h2.accent {
		
		position:relative;
		
		top:auto;
		left:auto;
		right:auto;
		bottom:auto;
		
		margin:20% auto auto auto;
		
		display:none;
		text-align:center;
		background-color:#000;
		color:#fff;	
		
		padding:1em 3em 1em 3em;
		
		font-size:1.25em;
		
		
		@media (min-width:$break-tablet) {
		
		display:inline-block;	
		margin:30% auto auto auto;	
		font-size:1.25em;	
			
		}
		
		@media (min-width:$break-desktop) {
			
			
			margin:20% auto auto auto;
			font-size:2em;
			
		}		
		
	}
	
	
	}


}


/** end home swiper **/



#reviews {

.swiper-container {
    width: 100%;
	background-color:#fff;
	text-align:center;
}   

.slide-review {

background-size: auto 100%;
background-position:center;
	
}


.slide-container {
max-width:960px;
margin:0 auto 0 auto;


	@media (max-width:480px)  {
		
			padding-top:95%;
			
	}

	@media (min-width:481px) and (max-width:767px) {
		
			padding-top:45%;
			
	}

	@media (min-width:768px) and (max-width:960px) {
		
			padding-top:30%;
			
	}
	
	
	
	@media (min-width:961px) and (max-width:1366px) {
		
			padding-top:28%;
			
	}
	
	@media (min-width:1367px)  {
		
			padding-top:28%;
			
	}

}

/* within the slide container */

.slide-container .content {
	
	@include position-cover;
	
		h1, h2 {
		
		position:absolute;
		left:0;
		right:0;
		bottom:2em;
		text-align:center;
		color:#fff;	
		
	}
	
	}


}

/* end reviews */




#hidef {


margin:0 auto 1.5em auto;

.swiper-container {
    width: 100%;
	background-color:#fff;
	text-align:center;
}   

.slide-hidef {

background-size: auto 100%;
background-position:center;
	
}


.slide-container {
max-width:960px;
margin:0 auto 0 auto;


	@media (max-width:480px)  {
		
			padding-top:95%;
			
	}

	@media (min-width:481px) and (max-width:767px) {
		
			padding-top:45%;
			
	}

	@media (min-width:768px) and (max-width:960px) {
		
			padding-top:40%;
			
	}
	
	
	
	@media (min-width:961px) and (max-width:1366px) {
		
			padding-top:48%;
			
	}
	
	@media (min-width:1367px)  {
		
			padding-top:48%;
			
	}

}

/* within the slide container */

.slide-container .content {
	
	@include position-cover;
	
		h1, h2 {
		
		position:absolute;
		left:0;
		right:0;
		bottom:2em;
		text-align:center;
		color:#fff;	
		
	}
	
	}


}

/* end hidef */

