/* Mixins */

@mixin rounded($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin width-page {
	
	max-width:960px;
	margin:0 auto 0 auto;
	position:relative;	
	
}

@mixin shadow-box($x, $y, $blur, $color) {
	box-shadow: $x $y $blur $color;
	}
	
	
@mixin shadow-box-inset($x, $y, $blur, $color) {
	box-shadow: inset $x $y $blur $color;
	}

@mixin shadow-text($x, $y, $blur, $color) {
	text-shadow: $x $y $blur $color;
	}


@mixin background-cover($path) {
	
	background: { 
		
		image: $path;
		repeat:no-repeat;
		position: center top;
		size: 100% auto;
	}
		
	
}

@mixin background-x($path) {
	
	background: { 
		
		image: $path;
		repeat:repeat-x;
		position: left top;
		background-size: auto 100%;
	}
		
	
}

@mixin transition($property, $speed) {
	-webkit-transition: $property, $speed ease;
	-moz-transition: $property, $speed ease;
	-o-transition: $property, $speed ease;
	transition: $property, $speed ease;
}

@mixin boxes {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing:border-box;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin gradient($from, $to) {
	/*fallback*/
	background-color: $to;
	background-image: -moz-linear-gradient($from, $to);
	background-image: -o-linear-gradient($from, $to);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0,$from),color-stop(1, $to));
	background-image: -webkit-linear-gradient($from, $to);
}

@mixin position-cover {

	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	display:block;
	
}
@mixin position-top {

	position:absolute;
	top:0;
	right:0;
	left:0;
	display:block;
	
}

@mixin position-bottom {

	position:absolute;
	bottom:0;
	right:0;
	left:0;
	display:block;
	
}

@mixin position-center ($m) {

	position:absolute;
	top:0;
	left:50%;
	margin:$m;	
	
}


@mixin banner-background {
	
	background: {
		repeat:no-repeat;
		position:center top;
		size:auto 100%;
	}
	
	
	@media (min-width: $break-tablet) {
		
	background: {
			repeat:no-repeat;
			position:center top;
			size:auto 100%;
		}		
		
	}

	@media (min-width: $break-desktop) {
		
	background: {
			repeat:no-repeat;
			position:center top;
			size:100% auto;
		}		
		
	}
	
}

	